import React from "react";
import { Link as InternalLink, useStaticQuery, graphql } from "gatsby";
import Menu from "../components/menu";
import { GatsbyImage } from "gatsby-plugin-image";
import { Typography, Container, Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const Header = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "img/logo/tanaschitaLogo.png" }) {
        childImageSharp {
          logo: gatsbyImageData(width: 60)
        }
      }
    }
  `);

  return (
    <HeaderContainer>
      <HeaderContent>
        <GatsbyImage
          image={data.file.childImageSharp.logo}
          alt="Logo for tanaschita.com"
        />
        <Box width="10px"></Box>
        <HeaderTitleButton key="tanaschita.com">
          <InternalLink to="/" style={{ textDecoration: "none" }}>
            <HeaderTitleLabel variant="button">tanaschita.com</HeaderTitleLabel>
          </InternalLink>
        </HeaderTitleButton>
        <Menu rootPath={props.rootPath} currentPath={props.currentPath} />
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.headerBackground,
  borderBottom: "2px solid",
  borderColor: theme.palette.headerSeparator,
  marginBottom: "16px",
  maxWidth: "none",
  paddingLeft: 0,
  paddingRight: 0,
  [theme.breakpoints.down("xs")]: {
    marginBottom: "12px",
  },
}));

const HeaderContent = styled(Container)(({ theme }) => ({
  paddingTop: "16px",
  maxWidth: "1000px",
  paddingLeft: "16px",
  paddingRight: "16px",
  display: "flex",
  flexWrap: "wrap",
}));

const HeaderTitleLabel = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "20px",
  letterSpacing: "2px",
  [theme.breakpoints.down("xs")]: {
    fontSize: "18px",
  },
}));

const HeaderTitleButton = styled(Button)(({ theme }) => ({
  marginTop: "20px",
  marginRight: "20px",
}));
