import React from "react";
import "./layout.css";
import CreateTanaschitaTheme from "./globalTheme";
import Header from "../components/header";
import Footer from "../components/footer";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const Layout = ({ location, children, classes }) => {
  const rootPath = `${__PATH_PREFIX__}/`;

  return (
    <ThemeProvider theme={CreateTanaschitaTheme()}>
      <CssBaseline />
      <div>
        <header>
          <Header rootPath={rootPath} currentPath={location.pathname} />
        </header>
        <main>{children}</main>
        <footer>
          <Footer rootPath={rootPath} currentPath={location.pathname} />
        </footer>
      </div>
    </ThemeProvider>
  );
};

export default Layout;
