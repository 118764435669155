import React from "react";
import { Link as InternalLink } from "gatsby";
import { colors } from "../styles/globalStyles";
import { Typography, Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

const Footer = (props) => {
  const footerLinks = [
    {
      name: "Privacy policy",
      path: "/privacy-policy/",
    },
    {
      name: "Impressum",
      path: "/impressum/",
    },
  ];

  return (
    <FooterContainer>
      <Container>
        <Box height="8px"></Box>
        <Typography variant="body2">
          © {new Date().getFullYear()} tanaschita.com
        </Typography>
        <Box height="8px"></Box>
        {footerLinks.map((link) => (
          <FooterLink key={link.name} to={link.path}>
            <Typography variant="body2">{link.name}</Typography>
            <Box height="4px"></Box>
          </FooterLink>
        ))}
        <Box height="8px"></Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled("div")(({ theme }) => ({
  borderTop: "1px solid #eee",
  marginTop: "36px",
  textAlign: "center",
  maxWidth: "none",
  paddingLeft: 0,
  paddingRight: 0,
}));

const FooterLink = styled(InternalLink)(({ theme }) => ({
  color: colors.textColorLight,
  textDecoration: "none",
}));
