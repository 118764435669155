import { styled } from "@mui/material/styles";
import { Typography, Container } from "@mui/material";

export const colors = {
  primaryColor: "#AE2829",
  textColorLight: "#8f8f8f",
  backgroundColor: "#f2f2f2",
  secondary: "#725752",
};

export const SubHeadline = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  color: "#8f8f8f",
  borderBottom: "1px solid " + theme.palette.divider,
  letterSpacing: "0.1em",
  fontSize: "16px",
  marginTop: "32px",
  marginBottom: "18px",
}));
