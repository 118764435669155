import React from "react";
import { Link as InternalLink } from "gatsby";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const Menu = (props) => {
  const mainMenu = [
    { name: "Articles", path: "/" },
    { name: "Books", path: "/books/" },
    { name: "Sponsorship", path: "/sponsorship/" },
    { name: "Newsletter", path: "/newsletter/" },
    { name: "About", path: "/about/" },
  ];

  return (
    <MenuContainer>
      {mainMenu.map((link) => (
        <Button key={link.name}>
          <MenuLink to={link.path}>
            <MenuLabel variant="button">{link.name}</MenuLabel>
          </MenuLink>
        </Button>
      ))}
    </MenuContainer>
  );
};

export default Menu;

const MenuContainer = styled("div")(({ theme }) => ({
  marginTop: "20px",
  display: "flex",
  flexWrap: "wrap",
}));

const MenuLabel = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  letterSpacing: "1px",
  textTransform: "none",
  [theme.breakpoints.down("xs")]: {
    fontSize: "16px",
  },
}));

const MenuLink = styled(InternalLink)(({ theme }) => ({
  textDecoration: "none",
}));
