import React from "react";
import { createTheme } from "@mui/material/styles";
import { colors } from "./globalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";

function CreateTanaschitaTheme() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 400,
            md: 650,
            lg: 1000,
            xl: 1200,
          },
        },
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            main: colors.primaryColor,
          },
          secondary: {
            main: colors.secondary,
          },
          headerBackground: prefersDarkMode ? "#141414" : "#f7f7f7",
          headerSeparator: prefersDarkMode ? "#323232" : "#f0f0f0",
          adBackground: prefersDarkMode ? "#323232" : "#87634914",
          articleGridBackground: prefersDarkMode ? "#282b2e" : "#ebf1f7",
          subheadlineTextColor: prefersDarkMode ? "#000000" : "#636363",
        },
        typography: {
          fontFamily: [
            "charter",
            "Georgia",
            "Cambria",
            "Times New Roman",
            "Times",
            "serif",
          ].join(","),
          body1: {
            fontSize: "20px",
          },
          button: {
            color: colors.primaryColor,
            fontWeight: "600",
          },
        },
      }),
    [prefersDarkMode]
  );

  theme.typography.h1 = {
    fontSize: "32px",
    marginBottom: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: 1.1,
    },
  };

  theme.typography.h2 = {
    fontSize: "26px",
    fontWeight: "500",
    marginTop: "12px",
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: 1.1,
    },
  };

  theme.typography.h3 = {
    fontSize: "24px",
    fontWeight: "700",
    marginTop: "12px",
    marginBottom: "4px",
  };

  theme.typography.h4 = {
    fontSize: "20px",
    fontWeight: "700",
    marginTop: "12px",
    marginBottom: "4px",
  };

  return theme;
}

export default CreateTanaschitaTheme;
